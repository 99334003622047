import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "FHEM with INSTAR WQHD Cameras",
  "path": "/Frequently_Asked_Question/FHEM_with_INSTAR_WQHD_Cameras/",
  "dateChanged": "2022-05-12",
  "author": "Mike Polinowski",
  "excerpt": "Use the official Docker image to set up your FHEM Server.",
  "image": "./FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "WQHD & UHD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='FAQs - FHEM with INSTAR WQHD Cameras' dateChanged='2018-03-14' author='Mike Polinowski' tag='INSTAR IP Camera' description='Use the official Docker image to set up your FHEM Server.' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/FHEM_mit_INSTAR_WQHD_Kameras/' locationFR='/fr/Frequently_Asked_Question/FHEM_with_INSTAR_WQHD_Cameras/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "fhem-with-instar-wqhd-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#fhem-with-instar-wqhd-cameras",
        "aria-label": "fhem with instar wqhd cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`FHEM with INSTAR WQHD Cameras`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: I found your `}<a parentName="p" {...{
        "href": "/en/Advanced_User/FHEM_on_a_Raspberry_Pi/"
      }}>{`FHEM Installation Guide`}</a>{` for a Raspberry Pi. Can I use this software together with the new WQHD camera models?`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: Yes, and the installation is basically identical to the Full HD camera installation.`}</p>
    {/* TOC */}
    <ul>
      <li parentName="ul"><img parentName="li" {...{
          "src": "./#fhem-with-instar-wqhd-cameras",
          "alt": "FHEM with INSTAR WQHD Cameras"
        }}></img>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#adding-timed-snapshots"
            }}>{`Adding Timed Snapshots`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#adding-the-live-video"
            }}>{`Adding the Live Video`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#adding-an-alarnserver-webhook"
            }}>{`Adding an Alarnserver Webhook`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#connecting-to-the-mqtt-interface"
            }}>{`Connecting to the MQTT Interface`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#using-the-mqtt-alarmserver"
                }}>{`Using the MQTT Alarmserver`}</a></li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    <h2 {...{
      "id": "adding-timed-snapshots",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#adding-timed-snapshots",
        "aria-label": "adding timed snapshots permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adding Timed Snapshots`}</h2>
    <p>{`Start by adding your camera using the `}<a parentName="p" {...{
        "href": "https://fhem.de/commandref.html#IPCAM"
      }}>{`IPCAM module`}</a>{` and add your cameras snapshot path, e.g. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`http://192.168.2.115:80/snap.cgi?chn=12&user=admin&pwd=instar`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`define WQHDCam IPCAM `}<span parentName="code" {...{
            "className": "token number"
          }}>{`192.168`}</span>{`.2.115:80
attr WQHDCam path snap.cgi?chn`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`12`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`user`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pwd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar
attr WQHDCam delay `}<span parentName="code" {...{
            "className": "token number"
          }}>{`10`}</span>{`
attr WQHDCam snapshots `}<span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span>{`
attr WQHDCam storage /opt/fhem/www/snapshots
attr WQHDCam icon it_camera`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b99eecc5dc111fc9b0dd52359c456fe4/99661/FHEM_with_INSTAR_WQHD_Cameras_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.65217391304347%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACEUlEQVQoz3XSy07bQBQGYN6ARVhUEITHiS/jy3jul0ygdmObkJCiinYVukXpGtFHaEvZIKquI/GkVSbQElS+zYws/TrHv72FELq6uppMJhhjSinBmBBKCSWEMMbquh6Px8fO+lI/swXj+GG5/HB2RjC21iZJ0uv1+o7neRBC5GRZFkURpbRpmn9hLMSO53XD4PDwSErJGKuqd0dHb8uyms3ecy6kwzlHCHHON8IzrT9G4anfa+t6Mp2en5/f3/9eLh/u7n5dXi6UUpwzzjljrCzLl2t/Ymy+szPb3m6q6ni8erH5fL5YfLm4+Ewp0VobR2tdluXzsaswU+qN73e6XTscDq0tHErpY3+r9h5hjKWUG2tLIfyDg+7urhAiiiIAAISQMZbneRAEURT1+33f8TwvTdONsJDS87xOpyOVgjAGAKRpijGOnDiOgyDoOQCALMs2wtZavPq2RCkVhqvJSZJACAEA/pNXw43Ttu1oNLLWKqWMMYOBUUpppbRrTDtKqeFwuFHY+hg5WmtCiJTKmIHVplbaYJynaeasu9iY/Pd24kynp9fXX3/c3P68uf32/WZ8MmaMKa05Z2mavvxJ1kfTNGVZFkWBEMKYQCdJEoTQ+qEQwhjzangwGPg+AB4oChyG67JX/e3v7+/t7cVxXBRFGIZt2/5n7aqqOOdFUWilhRCMMfQkz3POuTGGUvq8sD8z3csJXsAZkwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b99eecc5dc111fc9b0dd52359c456fe4/e4706/FHEM_with_INSTAR_WQHD_Cameras_01.avif 230w", "/en/static/b99eecc5dc111fc9b0dd52359c456fe4/d1af7/FHEM_with_INSTAR_WQHD_Cameras_01.avif 460w", "/en/static/b99eecc5dc111fc9b0dd52359c456fe4/7f308/FHEM_with_INSTAR_WQHD_Cameras_01.avif 920w", "/en/static/b99eecc5dc111fc9b0dd52359c456fe4/2fdb2/FHEM_with_INSTAR_WQHD_Cameras_01.avif 1098w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b99eecc5dc111fc9b0dd52359c456fe4/a0b58/FHEM_with_INSTAR_WQHD_Cameras_01.webp 230w", "/en/static/b99eecc5dc111fc9b0dd52359c456fe4/bc10c/FHEM_with_INSTAR_WQHD_Cameras_01.webp 460w", "/en/static/b99eecc5dc111fc9b0dd52359c456fe4/966d8/FHEM_with_INSTAR_WQHD_Cameras_01.webp 920w", "/en/static/b99eecc5dc111fc9b0dd52359c456fe4/4eac3/FHEM_with_INSTAR_WQHD_Cameras_01.webp 1098w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b99eecc5dc111fc9b0dd52359c456fe4/81c8e/FHEM_with_INSTAR_WQHD_Cameras_01.png 230w", "/en/static/b99eecc5dc111fc9b0dd52359c456fe4/08a84/FHEM_with_INSTAR_WQHD_Cameras_01.png 460w", "/en/static/b99eecc5dc111fc9b0dd52359c456fe4/c0255/FHEM_with_INSTAR_WQHD_Cameras_01.png 920w", "/en/static/b99eecc5dc111fc9b0dd52359c456fe4/99661/FHEM_with_INSTAR_WQHD_Cameras_01.png 1098w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b99eecc5dc111fc9b0dd52359c456fe4/c0255/FHEM_with_INSTAR_WQHD_Cameras_01.png",
              "alt": "FHEM with INSTAR WQHD Cameras",
              "title": "FHEM with INSTAR WQHD Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`This sets the snapshot folder to a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/opt/fhem/www/snapshots`}</code>{`. All the folders inside the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/opt/fhem/www`}</code>{` directory belong the the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`user/group 6061`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`ls`}</span>{` -la /opt/fhem/www
     
total `}<span parentName="code" {...{
            "className": "token number"
          }}>{`36`}</span>{`
drwxr-x---  `}<span parentName="code" {...{
            "className": "token number"
          }}>{`9`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`6061`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`6061`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4096`}</span>{` May `}<span parentName="code" {...{
            "className": "token number"
          }}>{`12`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`17`}</span>{`:55 `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`.`}</span>{`
drwxr-x--- `}<span parentName="code" {...{
            "className": "token number"
          }}>{`15`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`6061`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`6061`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4096`}</span>{` May `}<span parentName="code" {...{
            "className": "token number"
          }}>{`12`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`17`}</span>{`:39 `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`
drwxr-x---  `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`6061`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`6061`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4096`}</span>{` Apr `}<span parentName="code" {...{
            "className": "token number"
          }}>{`25`}</span>{` 07:09 codemirror
drwxr-x---  `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`6061`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`6061`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4096`}</span>{` Apr `}<span parentName="code" {...{
            "className": "token number"
          }}>{`25`}</span>{` 07:09 frontend
drwxr-x---  `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`6061`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`6061`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4096`}</span>{` Apr `}<span parentName="code" {...{
            "className": "token number"
          }}>{`25`}</span>{` 07:09 gplot
drwxr-x---  `}<span parentName="code" {...{
            "className": "token number"
          }}>{`6`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`6061`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`6061`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4096`}</span>{` Apr `}<span parentName="code" {...{
            "className": "token number"
          }}>{`25`}</span>{` 07:09 images
drwxr-x---  `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`6061`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`6061`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4096`}</span>{` Apr `}<span parentName="code" {...{
            "className": "token number"
          }}>{`25`}</span>{` 07:09 jscolor
drwxr-x---  `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`6061`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`6061`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4096`}</span>{` Apr `}<span parentName="code" {...{
            "className": "token number"
          }}>{`25`}</span>{` 07:09 pgm2`}</code></pre></div>
    <p>{`So create the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`snapshots`}</code>{` folder and modify it accordingly:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`mkdir`}</span>{` /opt/fhem/www/snapshots
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`chown`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`6061`}</span>{`:6061 /opt/fhem/www/snapshots`}</code></pre></div>
    <p>{`To test if it is working we can hit the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`get image`}</code>{` button:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ed1c5987169cdd2c94425d258f460daa/99f37/FHEM_with_INSTAR_WQHD_Cameras_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "23.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAA3ElEQVQY013NzUrDQBSG4VxAL0GhgqFm5syZ6RmGgZAhkZoxWSRiiFvjwr0/W7eKDQ65hOKlSgulwWf5wcsXtW3b9z0iKrUWQuARAFhrq6q6nfHe38xE0zSFEJbLC+9LIuIc+AFjDACISB8QkZQyTdOyLE/xFWOXqxVpbawVQgDnApExprWWUgFwpRTuFx7HsTFmfh4ZgHUcX+f5fdPUdf3y9r7b/Y7jTwjTMDxZa51zWZY557z389t9/HF+9rpYNER3m81DkT8/Dp9f39vt2HVdkiSIKI6KovgX/wFDQkuJ9Ol05AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ed1c5987169cdd2c94425d258f460daa/e4706/FHEM_with_INSTAR_WQHD_Cameras_02.avif 230w", "/en/static/ed1c5987169cdd2c94425d258f460daa/d1af7/FHEM_with_INSTAR_WQHD_Cameras_02.avif 460w", "/en/static/ed1c5987169cdd2c94425d258f460daa/7f308/FHEM_with_INSTAR_WQHD_Cameras_02.avif 920w", "/en/static/ed1c5987169cdd2c94425d258f460daa/e449d/FHEM_with_INSTAR_WQHD_Cameras_02.avif 1100w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ed1c5987169cdd2c94425d258f460daa/a0b58/FHEM_with_INSTAR_WQHD_Cameras_02.webp 230w", "/en/static/ed1c5987169cdd2c94425d258f460daa/bc10c/FHEM_with_INSTAR_WQHD_Cameras_02.webp 460w", "/en/static/ed1c5987169cdd2c94425d258f460daa/966d8/FHEM_with_INSTAR_WQHD_Cameras_02.webp 920w", "/en/static/ed1c5987169cdd2c94425d258f460daa/abb12/FHEM_with_INSTAR_WQHD_Cameras_02.webp 1100w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ed1c5987169cdd2c94425d258f460daa/81c8e/FHEM_with_INSTAR_WQHD_Cameras_02.png 230w", "/en/static/ed1c5987169cdd2c94425d258f460daa/08a84/FHEM_with_INSTAR_WQHD_Cameras_02.png 460w", "/en/static/ed1c5987169cdd2c94425d258f460daa/c0255/FHEM_with_INSTAR_WQHD_Cameras_02.png 920w", "/en/static/ed1c5987169cdd2c94425d258f460daa/99f37/FHEM_with_INSTAR_WQHD_Cameras_02.png 1100w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ed1c5987169cdd2c94425d258f460daa/c0255/FHEM_with_INSTAR_WQHD_Cameras_02.png",
              "alt": "FHEM with INSTAR WQHD Cameras",
              "title": "FHEM with INSTAR WQHD Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Check the storage directory - it should now contain the 5 images:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`ls`}</span>{` -la /opt/fhem/www/snapshots

total `}<span parentName="code" {...{
            "className": "token number"
          }}>{`576`}</span>{`
drwxr-xr-x `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`6061`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`6061`}</span>{`  `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4096`}</span>{` May `}<span parentName="code" {...{
            "className": "token number"
          }}>{`12`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`18`}</span>{`:13 `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`.`}</span>{`
drwxr-x--- `}<span parentName="code" {...{
            "className": "token number"
          }}>{`9`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`6061`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`6061`}</span>{`  `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4096`}</span>{` May `}<span parentName="code" {...{
            "className": "token number"
          }}>{`12`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`17`}</span>{`:55 `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`
-rw-r----- `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`6061`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`6061`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`93529`}</span>{` May `}<span parentName="code" {...{
            "className": "token number"
          }}>{`12`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`18`}</span>{`:12 WQHDCam_snapshot_1.jpg
-rw-r----- `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`6061`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`6061`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`93991`}</span>{` May `}<span parentName="code" {...{
            "className": "token number"
          }}>{`12`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`18`}</span>{`:13 WQHDCam_snapshot_2.jpg
-rw-r----- `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`6061`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`6061`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`94439`}</span>{` May `}<span parentName="code" {...{
            "className": "token number"
          }}>{`12`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`18`}</span>{`:13 WQHDCam_snapshot_3.jpg
-rw-r----- `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`6061`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`6061`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`94784`}</span>{` May `}<span parentName="code" {...{
            "className": "token number"
          }}>{`12`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`18`}</span>{`:13 WQHDCam_snapshot_4.jpg
-rw-r----- `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`6061`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`6061`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`94689`}</span>{` May `}<span parentName="code" {...{
            "className": "token number"
          }}>{`12`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`18`}</span>{`:13 WQHDCam_snapshot_5.jpg
-rw-r----- `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`6061`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`6061`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`94689`}</span>{` May `}<span parentName="code" {...{
            "className": "token number"
          }}>{`12`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`18`}</span>{`:13 WQHDCam_snapshot.jpg`}</code></pre></div>
    <p>{`To be able to access those snapshots we can use the integrated webserver inside FHEM called HTTPSRV and tell it to serve the content of the storage directory define:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` HTTPSRV `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`infix`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`directory`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`friendlyname`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span></code></pre></div>
    <ul>
      <li parentName="ul"><strong parentName="li">{`name`}</strong>{`:  is a custom name for the device used by the FHEM UI.`}</li>
      <li parentName="ul"><strong parentName="li">{`infix`}</strong>{`:  a prefix for the FHEMWEB-Base-URL under which the content of the directory will be served.`}</li>
      <li parentName="ul"><strong parentName="li">{`directory`}</strong>{`:  the absolute path to the public snapshot directory you created earlier.`}</li>
      <li parentName="ul"><strong parentName="li">{`friendlyname`}</strong>{`:  the name that is used in the FHEM side navigation.`}</li>
    </ul>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`define SnapshotServer HTTPSRV snapshots /opt/fhem/www/snapshots WQHDCam-Snapshots
attr SnapshotServer icon dog_silhouette`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/263278ad7833c099fa0ce1f51eb43c5b/99661/FHEM_with_INSTAR_WQHD_Cameras_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49.130434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABYklEQVQoz1XRQVLCMBQGYI7gxqkMlo50IKW2aV7aJqEJDVSCdaeDukFdOWy8ATh4K87n0FqoXzbZ/Hnz/nQAYLPZCCGSJMYYRw2MMQAYYx7K8r5SVowxi0aHUno4HPI855xrrSml0BLHcZIkUsosy+r7fD4/5TthGCKEPM8rikIpJYQQ/Hja6rCo/AvTNB2MRl4QZFL6vh+GIVSzaYUQgjH2KuPKdDo9hzUF5jik3+ecu65LKWWMnTZHCLmue9MipVwul3/hzyD4urj46HZTxgaDAcZ4PB5fHVmWZdm2PfxPKdXaGeDy+vrKtouiuFssirti/fa+3X1vt7v9/me9foui6Lbh+36e5+cwiSLHtp1+vy4sy7KyLF9fXh8fn1arZ2NMkiQAQBpa63MYR1Gv17MsK9fH30rTNK7UOwOAlHIiBGvMZrNW23E8HA0RQlprpVQ9h3M+mUwYY4SQ+pVTke3Jv894gjwZjMXGAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/263278ad7833c099fa0ce1f51eb43c5b/e4706/FHEM_with_INSTAR_WQHD_Cameras_03.avif 230w", "/en/static/263278ad7833c099fa0ce1f51eb43c5b/d1af7/FHEM_with_INSTAR_WQHD_Cameras_03.avif 460w", "/en/static/263278ad7833c099fa0ce1f51eb43c5b/7f308/FHEM_with_INSTAR_WQHD_Cameras_03.avif 920w", "/en/static/263278ad7833c099fa0ce1f51eb43c5b/2fdb2/FHEM_with_INSTAR_WQHD_Cameras_03.avif 1098w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/263278ad7833c099fa0ce1f51eb43c5b/a0b58/FHEM_with_INSTAR_WQHD_Cameras_03.webp 230w", "/en/static/263278ad7833c099fa0ce1f51eb43c5b/bc10c/FHEM_with_INSTAR_WQHD_Cameras_03.webp 460w", "/en/static/263278ad7833c099fa0ce1f51eb43c5b/966d8/FHEM_with_INSTAR_WQHD_Cameras_03.webp 920w", "/en/static/263278ad7833c099fa0ce1f51eb43c5b/4eac3/FHEM_with_INSTAR_WQHD_Cameras_03.webp 1098w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/263278ad7833c099fa0ce1f51eb43c5b/81c8e/FHEM_with_INSTAR_WQHD_Cameras_03.png 230w", "/en/static/263278ad7833c099fa0ce1f51eb43c5b/08a84/FHEM_with_INSTAR_WQHD_Cameras_03.png 460w", "/en/static/263278ad7833c099fa0ce1f51eb43c5b/c0255/FHEM_with_INSTAR_WQHD_Cameras_03.png 920w", "/en/static/263278ad7833c099fa0ce1f51eb43c5b/99661/FHEM_with_INSTAR_WQHD_Cameras_03.png 1098w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/263278ad7833c099fa0ce1f51eb43c5b/c0255/FHEM_with_INSTAR_WQHD_Cameras_03.png",
              "alt": "FHEM with INSTAR WQHD Cameras",
              "title": "FHEM with INSTAR WQHD Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Die oben angelegten Snapshots sind jetzt über den Browser erreichbar - z.B. mit `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/fhem/snapshots/WQHDCam_snapshot.jpg`}</code>{`.`}</p>
    <p>{`And now it all comes together. By creating a task that triggers the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`get image`}</code>{` function once a minute we can embedd a `}<em parentName="p">{`1-frame-per-minute`}</em>{` livestream of our camera `}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`define WQHDCam_Timelapse at +*00:01:00 get WQHDCam image
attr WQHDCam_Timelapse icon time_timer`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/987e05d3da9130d690e6eb4d0badb53c/99f37/FHEM_with_INSTAR_WQHD_Cameras_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "79.13043478260869%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACP0lEQVQoz3XRy27TQBSA4b4DpcFBzkx8Gdvj8XiccX1pfM340rphUVFUpCKxiChboDxEJRRRiZfp46E4dU0r8S28sX6NzjkHnudtNps4jhljGGPbton9yHEcIUTbts1z1eDAdd2HhwchRJZl3fl5uVotkyRN0yRJsizL8zx7Ls/zMaaUIoQsbAVBYBBiEWI5O4TYnHPbJpqm6bqu9VRVNU2zLMu6rndxGASWabqUOo6jKIppmHM4l2UZACBJ0mQymU6l6XQKAIAQAgA0TRtjyhcSBG/kWRiGi8XCdV1Cdq9BCNMsu7h4f3bWVVWDMVZ7CKExbhlbHh6GklSW5TBVttoRV1cfN5sv19efLi8/RFHkuq7v+0EQrFarx5k/u+zm6OhqMqmbpuu69Xrddd3t7c/t9vfNzVeEkGmalmXRXlEUp6en48Kw572azV4DUOSF7x87jkMpZYwd9zjnzPPcAaV0sVgIIR5jj7HZ26l0NAnD0DCM/WIUReGcR1HEOSfERgjB3suF+b5vGAbGOAiOtQFCyPM8QohpmrquI4SeTmUYxhhXVSVE1bbtycnSsqx5T9cRxjaEcD5Qev0v/UUsmqaJ45N9rCiKpmmuSy3LUobs/3FV7WPOOUIIAKCqqu/7jDFFUQAcvZx5v7e6rtMk4T3GGOfcHzDmMca8Aed8vPNTLKoq6M+TpimlDkLIMBDGeLlcFkUeRWHUi+N4PNXTxSsh8jwX/ff7tx/b7f3d3a/7+z/r9TtZlvcDQwj/nfkvoSXJst8PK7wAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/987e05d3da9130d690e6eb4d0badb53c/e4706/FHEM_with_INSTAR_WQHD_Cameras_04.avif 230w", "/en/static/987e05d3da9130d690e6eb4d0badb53c/d1af7/FHEM_with_INSTAR_WQHD_Cameras_04.avif 460w", "/en/static/987e05d3da9130d690e6eb4d0badb53c/7f308/FHEM_with_INSTAR_WQHD_Cameras_04.avif 920w", "/en/static/987e05d3da9130d690e6eb4d0badb53c/e449d/FHEM_with_INSTAR_WQHD_Cameras_04.avif 1100w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/987e05d3da9130d690e6eb4d0badb53c/a0b58/FHEM_with_INSTAR_WQHD_Cameras_04.webp 230w", "/en/static/987e05d3da9130d690e6eb4d0badb53c/bc10c/FHEM_with_INSTAR_WQHD_Cameras_04.webp 460w", "/en/static/987e05d3da9130d690e6eb4d0badb53c/966d8/FHEM_with_INSTAR_WQHD_Cameras_04.webp 920w", "/en/static/987e05d3da9130d690e6eb4d0badb53c/abb12/FHEM_with_INSTAR_WQHD_Cameras_04.webp 1100w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/987e05d3da9130d690e6eb4d0badb53c/81c8e/FHEM_with_INSTAR_WQHD_Cameras_04.png 230w", "/en/static/987e05d3da9130d690e6eb4d0badb53c/08a84/FHEM_with_INSTAR_WQHD_Cameras_04.png 460w", "/en/static/987e05d3da9130d690e6eb4d0badb53c/c0255/FHEM_with_INSTAR_WQHD_Cameras_04.png 920w", "/en/static/987e05d3da9130d690e6eb4d0badb53c/99f37/FHEM_with_INSTAR_WQHD_Cameras_04.png 1100w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/987e05d3da9130d690e6eb4d0badb53c/c0255/FHEM_with_INSTAR_WQHD_Cameras_04.png",
              "alt": "FHEM with INSTAR WQHD Cameras",
              "title": "FHEM with INSTAR WQHD Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`This image can be embedded inside a website. You can download `}<a parentName="p" {...{
        "href": "https://wiki.instar.com/dl/image-container.zip"
      }}>{`this HTML code`}</a>{` and unzip it to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/opt/fhem/www/snapshots`}</code>{`. This page will be hosted under `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/fhem/snapshots`}</code>{` and show you the latest snapshot inside a jQuery lightbox (see also `}<a parentName="p" {...{
        "href": "/en/Advanced_User/FHEM_on_a_Raspberry_Pi/#timed-snapshots"
      }}>{`Timed Snapshots in FHEM`}</a>{`).`}</p>
    <h2 {...{
      "id": "adding-the-live-video",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#adding-the-live-video",
        "aria-label": "adding the live video permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adding the Live Video`}</h2>
    <p>{`You can use the IFRAME module to add the cameras live video:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`define WQHDCamViewer weblink iframe http://192.168.2.115:80/livestream/12?action`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`play`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`media`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`mjpeg`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`user`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pwd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar
attr WQHDCamViewer htmlattr `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"640"`}</span>{` `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"360"`}</span>{` `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`frameborder`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span>{` `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`marginheight`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span>{` `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`marginwidth`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span>{`
attr WQHDCamViewer room INSTAR
attr WQHDCamViewer verbose `}<span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span></code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8f95231dcdb46343eb22a7b53246978b/eb4a1/FHEM_with_INSTAR_WQHD_Cameras_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.08695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACJklEQVQoz4WRy2sTURSHB2xrmjYh5tWpHSl5dCZp7tzcJPO487zzumOUoigoFWy0iwqtbREiWASpNNCNqKhUBFGKIOrWP8GtELpwrf+Ba3ElSTZN0fpxNmfxcc75HUYUxXa7vby8DABQFKXWByFUq9VEUTQMg1Ia/AOmVCp1Op1WqwUAcF0XAFAql0UIB+0xZk/WdT03O1vM5W3Lsglp9JFlWZKk/8vYNIVqdaZYMAmhlJqmSZwehBDXdYNjYc7r+oVC0c1kHIxlVQGgMl8u8zwvCILnuiHtEwSDOipfwvg6y16Ox3UIUUOCsOoFNAybzeY5rGm241j9HRzPc1zP9/3htQ2Dm5tLcdypVDoSSayut792v71++37/3cd6FcVTCR4CvioKqCZUazZxDqfAaBgXc7nT7FQ6kx0ZiT568fLn718HP75/6XbVuswwTF7gRUWGmlZRVOIMRchgjAvFQiqVTCSSyQz7eKfz+cOnvVd7z589yc+cGR0bK5ZLdVNvEBuZFvG8w5czlmUhhCCE6XQmOTX9Zuvu/vb27vrK5gKdmohFJ6I8qEi2ITtOwyZH5SAIKKXNZpPjuPHJ2L2LZ5+u3dppXbtN9UwsPhGbFGBPVn2v/lfZ9/0wDFl2OjIeXVm80nmwtfvw/uaCFRs9cTISKZQFqKkVSWKzWd0wwzAckgfDVVWVZXnp5o3VzY21OxtLi1fhfA8FY82y6rKCEPI87/Cr/gApEMMKDgG48wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8f95231dcdb46343eb22a7b53246978b/e4706/FHEM_with_INSTAR_WQHD_Cameras_05.avif 230w", "/en/static/8f95231dcdb46343eb22a7b53246978b/d1af7/FHEM_with_INSTAR_WQHD_Cameras_05.avif 460w", "/en/static/8f95231dcdb46343eb22a7b53246978b/7f308/FHEM_with_INSTAR_WQHD_Cameras_05.avif 920w", "/en/static/8f95231dcdb46343eb22a7b53246978b/a4458/FHEM_with_INSTAR_WQHD_Cameras_05.avif 1083w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8f95231dcdb46343eb22a7b53246978b/a0b58/FHEM_with_INSTAR_WQHD_Cameras_05.webp 230w", "/en/static/8f95231dcdb46343eb22a7b53246978b/bc10c/FHEM_with_INSTAR_WQHD_Cameras_05.webp 460w", "/en/static/8f95231dcdb46343eb22a7b53246978b/966d8/FHEM_with_INSTAR_WQHD_Cameras_05.webp 920w", "/en/static/8f95231dcdb46343eb22a7b53246978b/b671e/FHEM_with_INSTAR_WQHD_Cameras_05.webp 1083w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8f95231dcdb46343eb22a7b53246978b/81c8e/FHEM_with_INSTAR_WQHD_Cameras_05.png 230w", "/en/static/8f95231dcdb46343eb22a7b53246978b/08a84/FHEM_with_INSTAR_WQHD_Cameras_05.png 460w", "/en/static/8f95231dcdb46343eb22a7b53246978b/c0255/FHEM_with_INSTAR_WQHD_Cameras_05.png 920w", "/en/static/8f95231dcdb46343eb22a7b53246978b/eb4a1/FHEM_with_INSTAR_WQHD_Cameras_05.png 1083w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8f95231dcdb46343eb22a7b53246978b/c0255/FHEM_with_INSTAR_WQHD_Cameras_05.png",
              "alt": "FHEM with INSTAR WQHD Cameras",
              "title": "FHEM with INSTAR WQHD Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "adding-an-alarnserver-webhook",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#adding-an-alarnserver-webhook",
        "aria-label": "adding an alarnserver webhook permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adding an Alarnserver Webhook`}</h2>
    <p>{`I now want to use the cameras HTTP Alarmserver to contact FHEM in case of an alert and toggle a switch for me called `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`AlarmServer`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`define AlarmServer dummy
attr AlarmServer setList on off
attr AlarmServer room INSTAR
attr AlarmServer icon secur_alarm`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/fc9bff96b4ac104d56cc69796df7ff16/33a7b/FHEM_with_INSTAR_WQHD_Cameras_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "14.347826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAIAAAAcOLh5AAAACXBIWXMAAA7rAAAO6wFxzYGVAAAAa0lEQVQI12XJWwqAIBCFYdfiFRlshhQkEOpFcwOuwc25yMAgir6HnwOHtdZyzkSEiMuLcy6lVGs9p1LK+cPGGL13ADj2nRDdCxGtk/c+xniPpyEEhoic822y1qovOSmlAEBKaYwRQmit7/cC4aUlnqaKdYEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fc9bff96b4ac104d56cc69796df7ff16/e4706/FHEM_with_INSTAR_WQHD_Cameras_06.avif 230w", "/en/static/fc9bff96b4ac104d56cc69796df7ff16/d1af7/FHEM_with_INSTAR_WQHD_Cameras_06.avif 460w", "/en/static/fc9bff96b4ac104d56cc69796df7ff16/7f308/FHEM_with_INSTAR_WQHD_Cameras_06.avif 920w", "/en/static/fc9bff96b4ac104d56cc69796df7ff16/a9037/FHEM_with_INSTAR_WQHD_Cameras_06.avif 1119w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/fc9bff96b4ac104d56cc69796df7ff16/a0b58/FHEM_with_INSTAR_WQHD_Cameras_06.webp 230w", "/en/static/fc9bff96b4ac104d56cc69796df7ff16/bc10c/FHEM_with_INSTAR_WQHD_Cameras_06.webp 460w", "/en/static/fc9bff96b4ac104d56cc69796df7ff16/966d8/FHEM_with_INSTAR_WQHD_Cameras_06.webp 920w", "/en/static/fc9bff96b4ac104d56cc69796df7ff16/03b0b/FHEM_with_INSTAR_WQHD_Cameras_06.webp 1119w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fc9bff96b4ac104d56cc69796df7ff16/81c8e/FHEM_with_INSTAR_WQHD_Cameras_06.png 230w", "/en/static/fc9bff96b4ac104d56cc69796df7ff16/08a84/FHEM_with_INSTAR_WQHD_Cameras_06.png 460w", "/en/static/fc9bff96b4ac104d56cc69796df7ff16/c0255/FHEM_with_INSTAR_WQHD_Cameras_06.png 920w", "/en/static/fc9bff96b4ac104d56cc69796df7ff16/33a7b/FHEM_with_INSTAR_WQHD_Cameras_06.png 1119w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/fc9bff96b4ac104d56cc69796df7ff16/c0255/FHEM_with_INSTAR_WQHD_Cameras_06.png",
              "alt": "FHEM with INSTAR WQHD Cameras",
              "title": "FHEM with INSTAR WQHD Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`This switch can now be toggled by sending a GET request to the following webhooks:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`http://192.168.2.111:8083/fhem?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`set%20AlarmServer%20on`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`XHR`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`
http://192.168.2.111:8083/fhem?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`set%20AlarmServer%20off`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`XHR`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span></code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4d34f4351efbeea91afe008004b2ce8e/e751c/FHEM_with_INSTAR_WQHD_Cameras_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "13.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAIAAAAcOLh5AAAACXBIWXMAAA7rAAAO6wFxzYGVAAAAaUlEQVQI12XKMQ6FIBBFUZZC40BAHGeSj9VMqXRsgX2yOaNG44+nuHnFM601VSWi6V9KSURKKdtpXbcv03uvtTKzqiJiekFEOjHR8puPwfRgZhNjtNaKSM45hOBe4OYc4DgAgPf+6nXYAd4CJbYoK+9ZAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4d34f4351efbeea91afe008004b2ce8e/e4706/FHEM_with_INSTAR_WQHD_Cameras_07.avif 230w", "/en/static/4d34f4351efbeea91afe008004b2ce8e/d1af7/FHEM_with_INSTAR_WQHD_Cameras_07.avif 460w", "/en/static/4d34f4351efbeea91afe008004b2ce8e/7f308/FHEM_with_INSTAR_WQHD_Cameras_07.avif 920w", "/en/static/4d34f4351efbeea91afe008004b2ce8e/0eb28/FHEM_with_INSTAR_WQHD_Cameras_07.avif 1123w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4d34f4351efbeea91afe008004b2ce8e/a0b58/FHEM_with_INSTAR_WQHD_Cameras_07.webp 230w", "/en/static/4d34f4351efbeea91afe008004b2ce8e/bc10c/FHEM_with_INSTAR_WQHD_Cameras_07.webp 460w", "/en/static/4d34f4351efbeea91afe008004b2ce8e/966d8/FHEM_with_INSTAR_WQHD_Cameras_07.webp 920w", "/en/static/4d34f4351efbeea91afe008004b2ce8e/33ebe/FHEM_with_INSTAR_WQHD_Cameras_07.webp 1123w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4d34f4351efbeea91afe008004b2ce8e/81c8e/FHEM_with_INSTAR_WQHD_Cameras_07.png 230w", "/en/static/4d34f4351efbeea91afe008004b2ce8e/08a84/FHEM_with_INSTAR_WQHD_Cameras_07.png 460w", "/en/static/4d34f4351efbeea91afe008004b2ce8e/c0255/FHEM_with_INSTAR_WQHD_Cameras_07.png 920w", "/en/static/4d34f4351efbeea91afe008004b2ce8e/e751c/FHEM_with_INSTAR_WQHD_Cameras_07.png 1123w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4d34f4351efbeea91afe008004b2ce8e/c0255/FHEM_with_INSTAR_WQHD_Cameras_07.png",
              "alt": "FHEM with INSTAR WQHD Cameras",
              "title": "FHEM with INSTAR WQHD Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote">{`Note if calling the URL does not work and results in an `}<strong parentName="p">{`HTTP ERROR 400`}</strong>{` or just a white page, run the following command in the FHEM command line `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`attr WEB csrfToken none`}</code>{` and try again. See the `}<a parentName="p" {...{
          "href": "https://forum.fhem.de/index.php?topic=68957.0"
        }}>{`FHEM Forum`}</a>{` for more details.`}</p>
    </blockquote>
    <p>{`We now have a way to toggle a swith in FHEM when our cameras is triggered. All we need to do now is to add the FHEM Webhook we just created to the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Smarthome/Alarm_Server/"
      }}>{`Alarmserver Configuration`}</a>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5995b21dd13b7c2785447e603c6c6703/71d2f/FHEM_with_INSTAR_WQHD_Cameras_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "59.130434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACOUlEQVQoz1WRyU7bUBSGIzWOmaHdUDXYwQ5NPAmpUQk0g4dAQkN8c+frG4cCQiB10QUlgdIN275DX4AuKvUVqyTQ4dPZfjr/+U/G8zzXdR3HsSxrq2iWnO3u1ffe15+HNw+HNz9m0719wPe/vGpoFDS9sLmzU61UKi/X1zPOE+VyeWV59fnaCyjP04+3ycVoOtfJxUhejuTFdSMIq3vVt7vVIIz22+/zeT0zW2s7tlW2coqqZLNxrzccDhljlBKepFwIzhmTx7VGvdas133fj1qYJH7UyXieN5Ft27IsVVEX5pYQxonghOA4joMwbDYb9XotCkPKWOvgIIiid81GL8YAsszMnMlKNre0sIwQZowRQiCEnU67M6V71L37cgcgagatRhD2eqQPk0nsP4Upz5TVxRXKmJRSCEEpZTM4Pz09G49HAMIg2m8GEQAc4/RvYTN5bWmVc5GmKeecEIIQgggJjNNjMr4dgz6ayGEEYcLoh0d5Erts5bK5xbkFjNAgHVBKAQC+7/tB0ArDdnt/9PkKQOi3DhpBiPEgEWcT2XXd2c25nKoqar/fl1KSKWgKROjk5HRv297Q8rUg3NndJUgO08t/Nlv2/PyiqqggjqWUGE9qG8hk8ichZJoWi6ambeRfbTiuW3tT6R6RjP2EZVmaphcKBQCAEIIQQikRMuVymFKeUuY4zlbR1HVd07SCYRS3Xv8nG4ZRKpXiOCaPofvkfEw+fbsH5D6Gjufpmm6aRdM0DdPcNIzfjCmwN5+qTZIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5995b21dd13b7c2785447e603c6c6703/e4706/FHEM_with_INSTAR_WQHD_Cameras_08.avif 230w", "/en/static/5995b21dd13b7c2785447e603c6c6703/d1af7/FHEM_with_INSTAR_WQHD_Cameras_08.avif 460w", "/en/static/5995b21dd13b7c2785447e603c6c6703/7f308/FHEM_with_INSTAR_WQHD_Cameras_08.avif 920w", "/en/static/5995b21dd13b7c2785447e603c6c6703/e1c99/FHEM_with_INSTAR_WQHD_Cameras_08.avif 1380w", "/en/static/5995b21dd13b7c2785447e603c6c6703/f695f/FHEM_with_INSTAR_WQHD_Cameras_08.avif 1485w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5995b21dd13b7c2785447e603c6c6703/a0b58/FHEM_with_INSTAR_WQHD_Cameras_08.webp 230w", "/en/static/5995b21dd13b7c2785447e603c6c6703/bc10c/FHEM_with_INSTAR_WQHD_Cameras_08.webp 460w", "/en/static/5995b21dd13b7c2785447e603c6c6703/966d8/FHEM_with_INSTAR_WQHD_Cameras_08.webp 920w", "/en/static/5995b21dd13b7c2785447e603c6c6703/445df/FHEM_with_INSTAR_WQHD_Cameras_08.webp 1380w", "/en/static/5995b21dd13b7c2785447e603c6c6703/fe3fb/FHEM_with_INSTAR_WQHD_Cameras_08.webp 1485w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5995b21dd13b7c2785447e603c6c6703/81c8e/FHEM_with_INSTAR_WQHD_Cameras_08.png 230w", "/en/static/5995b21dd13b7c2785447e603c6c6703/08a84/FHEM_with_INSTAR_WQHD_Cameras_08.png 460w", "/en/static/5995b21dd13b7c2785447e603c6c6703/c0255/FHEM_with_INSTAR_WQHD_Cameras_08.png 920w", "/en/static/5995b21dd13b7c2785447e603c6c6703/b1001/FHEM_with_INSTAR_WQHD_Cameras_08.png 1380w", "/en/static/5995b21dd13b7c2785447e603c6c6703/71d2f/FHEM_with_INSTAR_WQHD_Cameras_08.png 1485w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5995b21dd13b7c2785447e603c6c6703/c0255/FHEM_with_INSTAR_WQHD_Cameras_08.png",
              "alt": "FHEM with INSTAR WQHD Cameras",
              "title": "FHEM with INSTAR WQHD Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And the next steps will be specific to your personal setup. Use the switch to trigger a function and toggle it back to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`off`}</code>{` to have it primed for the next alarm trigger.`}</p>
    <h2 {...{
      "id": "connecting-to-the-mqtt-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#connecting-to-the-mqtt-interface",
        "aria-label": "connecting to the mqtt interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Connecting to the MQTT Interface`}</h2>
    <p>{`You can use the camera internal MQTT Broker with the `}<a parentName="p" {...{
        "href": "https://fhem.de/commandref.html#MQTT2_CLIENT"
      }}>{`MQTT2 Client`}</a>{` to connect FHEM with your camera:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`define `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` MQTT2_CLIENT `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`host`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`:`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`port`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span></code></pre></div>
    <p>{`Start by activating the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Smarthome/MQTT/"
      }}>{`INSTAR MQTT Broker`}</a>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3047ffcefae241d887973c00de415878/d56e1/FHEM_with_INSTAR_WQHD_Cameras_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACFElEQVQoz12STW8SURSG2ZloGpMWUizzce8MZT6AdGZgsLXUxBY6A8PX3HvPXCi0SKvRqG1T3TRGd64M/gAXbVz6M40M0uKbs7ibJ+9zTm6iWCwWCoV8Pm/oua2dg+DTr9rl7f75zf757WJenN/WL27Yl9+Fane7bO9Wq+l0GmOcyP+Lrm06288HV1N+9QMup0tzMYXL7+PPP529BpLFnK5nBCGZTCVM04xhTddta4t2m1EYkLZP2n7Y8sK2Rzo+6fhh65AybtlbqSfpDVHIyNLqWmrebJqmpmmO44SERJy/nExOz85OxmPGgEEEEBFKOY9KrisgGWVVCWNBlpdg27Y558PhcDQaDYdHAACMAQClFCCaTE5LFVdUEM5tyqoiIvQ/DABRFDHGCCFhGFJK40ev1yNhWH5aEVWsaDmUVUWM/+5smqZhGPeajzjnZJZ2p+N5Xr1e9zwvgqhccWVVUXUNZbMCun9tXY+bB4NBBBDDM2FgjHHOX71+U6q4EkaimNkQRQGhhGEYsfYC7vf7sS0hpNPtNINWELSCZqMLx47rriZXHz988GhlJZXO3MGLnRfOlFL/sLa34z7b3SlVq3v1mlNyksnk+noaYSyI0hLsOA6llM0DjIZs/I68/3Yyevu1GVyHtOK6sixnVRVjrCjK/JPE2pZl+Xdp+If1Jj0JJtdAjj8e1D54DcuyJElSFAXP8gfR7LKyUA/ezgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3047ffcefae241d887973c00de415878/e4706/FHEM_with_INSTAR_WQHD_Cameras_09.avif 230w", "/en/static/3047ffcefae241d887973c00de415878/d1af7/FHEM_with_INSTAR_WQHD_Cameras_09.avif 460w", "/en/static/3047ffcefae241d887973c00de415878/7f308/FHEM_with_INSTAR_WQHD_Cameras_09.avif 920w", "/en/static/3047ffcefae241d887973c00de415878/b9baf/FHEM_with_INSTAR_WQHD_Cameras_09.avif 1130w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3047ffcefae241d887973c00de415878/a0b58/FHEM_with_INSTAR_WQHD_Cameras_09.webp 230w", "/en/static/3047ffcefae241d887973c00de415878/bc10c/FHEM_with_INSTAR_WQHD_Cameras_09.webp 460w", "/en/static/3047ffcefae241d887973c00de415878/966d8/FHEM_with_INSTAR_WQHD_Cameras_09.webp 920w", "/en/static/3047ffcefae241d887973c00de415878/bbe5b/FHEM_with_INSTAR_WQHD_Cameras_09.webp 1130w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3047ffcefae241d887973c00de415878/81c8e/FHEM_with_INSTAR_WQHD_Cameras_09.png 230w", "/en/static/3047ffcefae241d887973c00de415878/08a84/FHEM_with_INSTAR_WQHD_Cameras_09.png 460w", "/en/static/3047ffcefae241d887973c00de415878/c0255/FHEM_with_INSTAR_WQHD_Cameras_09.png 920w", "/en/static/3047ffcefae241d887973c00de415878/d56e1/FHEM_with_INSTAR_WQHD_Cameras_09.png 1130w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3047ffcefae241d887973c00de415878/c0255/FHEM_with_INSTAR_WQHD_Cameras_09.png",
              "alt": "FHEM with INSTAR WQHD Cameras",
              "title": "FHEM with INSTAR WQHD Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And define the MQTT Client with your camera IP and the MQTT Broker port:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`define inMQTTv5 MQTT2_CLIENT `}<span parentName="code" {...{
            "className": "token number"
          }}>{`192.168`}</span>{`.2.115:1883`}</code></pre></div>
    <p>{`Continue by adding your Broker login and click on connect to connect the client with your broker:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b513da2f2d609774fb69349b89c2f9f6/26a94/FHEM_with_INSTAR_WQHD_Cameras_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "68.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACDUlEQVQoz23S3WrbMBTA8b5BB2GDlDSJFVmVJftYki3JdpyPObaTfsFgSwcj3ehghO5izQbdk/UBR5xtNG1/V7rQnyPEORBCrNfrPM+lUkEQcM5d1z05OQkAAoCiKBaLxXw+r+q6fOaAc/7w8FAUxTgfnZ+fnS5OV6vr5YelldJKmShlpTRhOB4Oq6p6GodhGABwQpbTt2VdX15e3t//Xn9bWxUlcRwBCM5DxjJrZ4292BrDKQXPG41GQghCSLd7TAiRSoVCyGjLD4B6HiEEAPZiMOYNHhy227HWQoSUUtd1fc49zyOuixoDhAaDAUKIMbYXL5X61O2etdt5lgkpd5eUUtuBvs8aGGOEkOM4z2KA968OT1stqzXG2Ok7CCEpxK5xG7v5L8Sg9WuEWt1ukqaMeY7jYIyVigAA/TNovPBsJYRzfNw5OkqSBELo9Xr9fp9z7lGKEOo/0uv1KKV7sZCy0+m0Wq3xaFQUhTEmjvUw30rSNNI60jpuRFFkrd2L0zSVQkAYTssyTRJjjNZ6O5kx4HxiTJGmw+yvPM/34qqq6vn8oq7fTSZplpWz8vb2+93dZvPz1+bHJrfWw5g034Yx9n3/8Z4d7PamKoqLspxOp7PZ7Orq4/XnLzc3X1er62GeqyjSWiulpJRPn/3/NJ5MpJSMMUJcSilA4LqYcx5rnWUZAARBoJR6vKF/AD7etMWh0ujlAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b513da2f2d609774fb69349b89c2f9f6/e4706/FHEM_with_INSTAR_WQHD_Cameras_10.avif 230w", "/en/static/b513da2f2d609774fb69349b89c2f9f6/d1af7/FHEM_with_INSTAR_WQHD_Cameras_10.avif 460w", "/en/static/b513da2f2d609774fb69349b89c2f9f6/7f308/FHEM_with_INSTAR_WQHD_Cameras_10.avif 920w", "/en/static/b513da2f2d609774fb69349b89c2f9f6/24f2a/FHEM_with_INSTAR_WQHD_Cameras_10.avif 1102w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b513da2f2d609774fb69349b89c2f9f6/a0b58/FHEM_with_INSTAR_WQHD_Cameras_10.webp 230w", "/en/static/b513da2f2d609774fb69349b89c2f9f6/bc10c/FHEM_with_INSTAR_WQHD_Cameras_10.webp 460w", "/en/static/b513da2f2d609774fb69349b89c2f9f6/966d8/FHEM_with_INSTAR_WQHD_Cameras_10.webp 920w", "/en/static/b513da2f2d609774fb69349b89c2f9f6/5f375/FHEM_with_INSTAR_WQHD_Cameras_10.webp 1102w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b513da2f2d609774fb69349b89c2f9f6/81c8e/FHEM_with_INSTAR_WQHD_Cameras_10.png 230w", "/en/static/b513da2f2d609774fb69349b89c2f9f6/08a84/FHEM_with_INSTAR_WQHD_Cameras_10.png 460w", "/en/static/b513da2f2d609774fb69349b89c2f9f6/c0255/FHEM_with_INSTAR_WQHD_Cameras_10.png 920w", "/en/static/b513da2f2d609774fb69349b89c2f9f6/26a94/FHEM_with_INSTAR_WQHD_Cameras_10.png 1102w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b513da2f2d609774fb69349b89c2f9f6/c0255/FHEM_with_INSTAR_WQHD_Cameras_10.png",
              "alt": "FHEM with INSTAR WQHD Cameras",
              "title": "FHEM with INSTAR WQHD Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2686d99251226d4f2dc868ce6ed7aa4c/f32b7/FHEM_with_INSTAR_WQHD_Cameras_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "78.69565217391303%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACfElEQVQoz3WTy27TQBSG/QRdorZJfJv4OrbnYjse3xLb8ThphEulNpRSdUcRC0AUdccKQbk9RrdISKx4PVSnSQtSP81iFvPNOUfzj0AIuby8TNMUYew4jmVZAADTNG3bJoTUazjnRTEZ10dssgyyA8JawloBY3x9fZ2mKee8bdujw8OLi/fL5dOyLDnnTcObpuk2s2KS5ovve6d/2rPf82e/poc/BZ9SU9NcCJumGY/Hi8Xi/Pzl/v6T8XhcFMWkoyiKsqziOFrsPT89+9Duv2kWryb8hYAZ6xkGgJBFTNd1SZJ2d3cBAEaH2aEoiqoqfVGskbvM0seExIQQ3xdaStt+vxoMRqPIcV1NGw6HQ4KJ7/uWZemarus66FAUxYYQb0BIOEHoZGvrYHs7CENN01RVBQBgTBBChmGu6m9kCCEhZKMLMAi2JOmRqoZhuDoBAPA8T+9YyWqHLMv/yz6l8mCgiCJjbDUeAMBxHMMwlDUPypTSXq+/s7MTRZFlWbIsdzLcjAoAeFBOkoRSOhqN4jgOAl9RFEmSTPNmWlmWpXsMBoNVcu7kLgDNbDarqirP8ziOR2uiKGJruqsDz/PwPYRpB+c8z3OEkO/7LIoopSyKkiS5EVz3ZnkehNB13X8qb9LbzOd10yyPjz9+uvp89fXLtx+v374LwhC6LnRdG0Lbth3H6R74FmGT+73JJCWYIZQRmlE6TZKUUoZQ7HkJITxJqOchjMldRjp5Wtdzzue+n7muD4DT73uiWGCSOTAYDmGvhxVl5lNLFFmS8Louy7KqqqIsb+WbX5EkvuchCD3bRhAmYUggRLbtWhZ2nJwxaFtZljVNs2p2Op3+BfKTxfZ5u3Y+AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2686d99251226d4f2dc868ce6ed7aa4c/e4706/FHEM_with_INSTAR_WQHD_Cameras_11.avif 230w", "/en/static/2686d99251226d4f2dc868ce6ed7aa4c/d1af7/FHEM_with_INSTAR_WQHD_Cameras_11.avif 460w", "/en/static/2686d99251226d4f2dc868ce6ed7aa4c/7f308/FHEM_with_INSTAR_WQHD_Cameras_11.avif 920w", "/en/static/2686d99251226d4f2dc868ce6ed7aa4c/47351/FHEM_with_INSTAR_WQHD_Cameras_11.avif 1136w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2686d99251226d4f2dc868ce6ed7aa4c/a0b58/FHEM_with_INSTAR_WQHD_Cameras_11.webp 230w", "/en/static/2686d99251226d4f2dc868ce6ed7aa4c/bc10c/FHEM_with_INSTAR_WQHD_Cameras_11.webp 460w", "/en/static/2686d99251226d4f2dc868ce6ed7aa4c/966d8/FHEM_with_INSTAR_WQHD_Cameras_11.webp 920w", "/en/static/2686d99251226d4f2dc868ce6ed7aa4c/588c1/FHEM_with_INSTAR_WQHD_Cameras_11.webp 1136w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2686d99251226d4f2dc868ce6ed7aa4c/81c8e/FHEM_with_INSTAR_WQHD_Cameras_11.png 230w", "/en/static/2686d99251226d4f2dc868ce6ed7aa4c/08a84/FHEM_with_INSTAR_WQHD_Cameras_11.png 460w", "/en/static/2686d99251226d4f2dc868ce6ed7aa4c/c0255/FHEM_with_INSTAR_WQHD_Cameras_11.png 920w", "/en/static/2686d99251226d4f2dc868ce6ed7aa4c/f32b7/FHEM_with_INSTAR_WQHD_Cameras_11.png 1136w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2686d99251226d4f2dc868ce6ed7aa4c/c0255/FHEM_with_INSTAR_WQHD_Cameras_11.png",
              "alt": "FHEM with INSTAR WQHD Cameras",
              "title": "FHEM with INSTAR WQHD Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "forwarding-fhem-events-through-mqtt",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#forwarding-fhem-events-through-mqtt",
        "aria-label": "forwarding fhem events through mqtt permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Forwarding FHEM Events through MQTT`}</h3>
    <p>{`Define an MQTT Bridge to publish FHEM Events through the MQTT interface of your camera:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`define MQTTBridge MQTT_GENERIC_BRIDGE
attr MQTTBridge IODev inMQTTv5
attr MQTTBridge globalDefaults sub:qos`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` pub:qos`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`retain`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`
attr MQTTBridge globalPublish *:topic`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"fhem/`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$device`}</span>{`/`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$reading`}</span>{`"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c91d684a7bb04cd3bead830cf514a548/4d383/FHEM_with_INSTAR_WQHD_Cameras_12.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "76.08695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACcUlEQVQoz2XR227TMBgH8DxFJ220ObQ5tEmcuHFOTp1kTZxDk0E1ptExOlRxgzbGNE1ME5t4gYnLSbwBF8AFb4ia0tGJnyzLF/77sz8zhmHc3t5SSjVV03Vd05bzakEIybKMUpqmNEnG4S71oxeWX5hOBlAKHcpACE9PTwkho2Bk27YsSzzPd7tdjuM6axwviD1uAHbTgx/18e/69a9k//vem58MxrgrCLquU0rDMKyq6uzs/OTk7Yfzj5eXVxcXl1dXnxaLdxj7juvvH8wn9WFCn5OodNwx4wcBL4qsIBRFUZTFfD5/ePh2f/+1LMvxOEmSNE1pFMV5nmOMJ/Vsb7qIk5eGaQCgMbHvq9vb8s4OpTSO47ARRRHGvu97zfAxxlEUWZaV5+Vsdjyp9pIkxThgjjzvfat1uLXl+f7qqf1+3zRNRemr6kBRlG5DaOR5Ppu9quuqruuyLBnX89os22ZZx3EkUeR5XlVVAIDwn16v53keIQStMZ7rPtvZ3mq1XNeVZZnneVmWdV0fDAa9Xm8zLIoiaBhrTBCM1EYcxwghy7Icx7Ft+/F4q4EQWgUghOYaQynNsizP8/F4bNv2cDiEEPq+hxCCEFqWRcKQRBFCaFXT3MCkaZo0PM8zTZPjOJZl5QbH82ynY0uiK0l8pyN0u+ZTy/AKxgFCSFGUdrvddJcXBEGSJASAYxiyJImStFkZQvg3TCktipzSbDqdXl/f3Nx8vrv7MpsdqapqQAhMU9O01bXB2rJhj5WTJHEbVVVXVT2ZVIQQo9mOlv1Ctm1HURRu+BcORoGmaSzL6kAfDoeaqsqyvPqt5o4mAIA+9QcfAcYfIBOPGwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c91d684a7bb04cd3bead830cf514a548/e4706/FHEM_with_INSTAR_WQHD_Cameras_12.avif 230w", "/en/static/c91d684a7bb04cd3bead830cf514a548/d1af7/FHEM_with_INSTAR_WQHD_Cameras_12.avif 460w", "/en/static/c91d684a7bb04cd3bead830cf514a548/7f308/FHEM_with_INSTAR_WQHD_Cameras_12.avif 920w", "/en/static/c91d684a7bb04cd3bead830cf514a548/7842e/FHEM_with_INSTAR_WQHD_Cameras_12.avif 1195w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c91d684a7bb04cd3bead830cf514a548/a0b58/FHEM_with_INSTAR_WQHD_Cameras_12.webp 230w", "/en/static/c91d684a7bb04cd3bead830cf514a548/bc10c/FHEM_with_INSTAR_WQHD_Cameras_12.webp 460w", "/en/static/c91d684a7bb04cd3bead830cf514a548/966d8/FHEM_with_INSTAR_WQHD_Cameras_12.webp 920w", "/en/static/c91d684a7bb04cd3bead830cf514a548/2ccb3/FHEM_with_INSTAR_WQHD_Cameras_12.webp 1195w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c91d684a7bb04cd3bead830cf514a548/81c8e/FHEM_with_INSTAR_WQHD_Cameras_12.png 230w", "/en/static/c91d684a7bb04cd3bead830cf514a548/08a84/FHEM_with_INSTAR_WQHD_Cameras_12.png 460w", "/en/static/c91d684a7bb04cd3bead830cf514a548/c0255/FHEM_with_INSTAR_WQHD_Cameras_12.png 920w", "/en/static/c91d684a7bb04cd3bead830cf514a548/4d383/FHEM_with_INSTAR_WQHD_Cameras_12.png 1195w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c91d684a7bb04cd3bead830cf514a548/c0255/FHEM_with_INSTAR_WQHD_Cameras_12.png",
              "alt": "FHEM with INSTAR WQHD Cameras",
              "title": "FHEM with INSTAR WQHD Cameras",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      